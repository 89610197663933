import { useState, useEffect, useRef } from "react";

import {
  getSurveyNotFromCMS,
  ILang,
  IPointInfo,
  getLanguages,
  getLanguagesFromCMS,
  getSurveyNot,
} from "../utilities/CmsUtils";
import ActionRectange from "../components/ActionRectangle";
import {
  IWindowDimensions,
  getWindowDimensions,
  getWindowScroll,
} from "../utilities/utils";
import Header from "../components/Header";
import { CommonStore } from "../stores/common";
import HeaderSticky from "../components/HeaderSticky";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";
import { motion } from "framer-motion";
import Cookies from "js-cookie";
import background from "../assets/images/fondo_vert2.jpg";
import { useCookiesContext } from "../context/CookiesCtx";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import background2 from "../assets/images/fondo_vert2.jpg";

const Intro = () => {
  const { trackPageView, trackEvent } = useMatomo();
  const setCurrentLang = CommonStore((state) => state.setCurrentLang);
  const languagesContainer = useRef<HTMLDivElement>(null);
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions()
  );
  const [showStickyMenu, setShowStickyMenu] = useState<boolean>(false);
  const [languages, setLanguages] = useState<ILang[] | undefined>();
  const setCurrentSurveyNot = CommonStore((state) => state.setCurrentSurveyNot);
  const setShowPointsList = CommonStore((state) => state.setShowPointsList);
  const [containerHeight, setContainerHeight] = useState<number>(
    getWindowDimensions().height
  );
  const setLanguageSelected = CommonStore((state) => state.setLanguageSelected);
  const { cookies, analyticsCookies, preferenceCookies } = useCookiesContext();

  useEffect(() => {
    window.scrollTo(0, 0);

    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    function handleScroll() {
      setContainerHeight(
        languagesContainer.current!.getBoundingClientRect().height
      );
      if (getWindowScroll() >= 100) {
        setShowStickyMenu(true);
      } else {
        setShowStickyMenu(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    getLanguagesFromCMS().finally(() => {
      setLanguages(getLanguages());
    });
  }, []);

  return (
    <div
      className="absolute top-0 z-10 app"
      style={{
        width: windowDimensions.width,
        backgroundColor: "#0900ba",
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.1,
        }}
      >
        <img
          src={background}
          style={{ height: "70vh" }}
          className="absolute w-full top-0"
        />
        <Header currentPage={"languages"} />
      </motion.div>

      <div
        className="absolute w-full pb-16 sm:mt-10"
        style={{ paddingTop: 220 }}
        ref={languagesContainer}
      >
        {languages !== undefined && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.1,
            }}
          >
            {languages.map((l, i) => (
              <ActionRectange
                key={i}
                title={l.name}
                numberName={-1}
                componentStyles={
                  "w-2/3 mx-8 sm:mx-16 sm:w-1/2 py-3 lg:w-1/3 lg:mx-20"
                }
                titleStyles={"text-2xl"}
                onClickAction={() => {
                  localStorage.setItem("lang", l.code);
                  if (cookies && preferenceCookies)
                    Cookies.set("cookie_language", l.code);
                  setCurrentLang(l);
                  setShowPointsList(true);
                  // GoogleAnalyticsUtilities.RecordUserInteractionEvent({
                  //   category:
                  //     ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.LANGUAGE,
                  //   label: l.code,
                  // });

                  setLanguageSelected(true);
                }}
              ></ActionRectange>
            ))}
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Intro;
