import {useEffect, useRef, useState} from "react";
import CommonStore from "../stores/common";
import {useSnackbar} from "notistack";
import ReactPlayer from "react-player";
import AudioPlayer from "./AudioPlayer";
import {isVideo} from "../utilities/utils";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const Poems = () => {
  const {trackPageView, trackEvent} = useMatomo();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const isPlaying = CommonStore((state) => state.isAudioPlaying);
  const setIsPlaying = CommonStore((state) => state.setIsAudioPlaying);
  const pointSelected = CommonStore((state) => state.pointSelected);
  const currentNotification = CommonStore((state) => state.currentNotification);
  const pauseVideos = CommonStore((state) => state.pauseVideos);
  const setCurrentMedia = CommonStore((state) => state.setCurrentMedia);
  const currentMedia = CommonStore((state) => state.currentMedia);
  const setCurrentNotification = CommonStore(
    (state) => state.setCurrentNotification
  );
  const currentLang = CommonStore((state) => state.currentLang);
  const toggleSendGAInfo = CommonStore((state) => state.toggleSendGAInfo);
  const [analyticSent, setAnalyticSent] = useState<boolean>(false);
  const refs = useRef<(ReactPlayer | null)[]>([]);

  // useEffect(() => {
  //   return () => console.log("Output poems");
  // }, []);

  // const pauseAll = (index: number) => {
  //   console.log("Pause all", index);

  //   //poemsControlPlaying.map((item, i) => console.log(item));

  //   setPoemsControlPlaying((prevState) =>
  //     prevState.map((item, idx) => (idx === index ? true : false))
  //   );

  //   //refs.current[index]?.seekTo(0);
  // };

  useEffect(() => {
    setCurrentMedia(-1);
  }, [pointSelected]);

  useEffect(() => {
    for (var i = 0; i < pointSelected.poems.length; i++) {
      if (
        isVideo(pointSelected.poems[i].blobFile.type) &&
        refs.current[i]?.getCurrentTime() !== 0 &&
        refs.current[i]?.getCurrentTime() !== null &&
        refs.current[i]?.getCurrentTime() !== undefined
      ) {
        var time = refs.current[i]?.getCurrentTime();
        var duration = refs.current[i]?.getDuration();
        if (time !== undefined && duration !== undefined) {
          // GoogleAnalyticsUtilities.RecordUserInteractionEvent({
          //   category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIA,
          //   action: pointSelected.poems[i].title,
          //   label: Math.round(time).toString(),
          // });
          trackEvent({
            category:
              ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIASECONDS,
            action: currentLang.code,
            name: pointSelected.poems[i].identifier,
            value: Math.round(time),
          });
          trackEvent({
            category:
              ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                .MULTIMEDIAPERCENTAGE,
            action: currentLang.code,
            name: pointSelected.poems[i].identifier,
            value: Math.round((time / duration) * 100),
          });
        }
      }
    }
  }, [toggleSendGAInfo]);

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <div className="w-10/12">
        {pointSelected.poems.map((element, i) => (
          <>
            {isVideo(element.blobFile.type) ? (
              <ReactPlayer
                key={element.title}
                ref={(el) => (refs.current[i] = el)}
                playsinline
                controls
                style={{}}
                url={element.file}
                className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
                width="100%"
                height="100%"
                onPlay={() => {
                  // pauseAll(i);
                  // setCurrentMedia(-1)
                  setCurrentMedia(i + 1);
                }}
                playing={currentMedia === i + 1}
                onEnded={() => {
                  var time = refs.current[i]?.getDuration();
                  if (time !== undefined) {
                    trackEvent({
                      category:
                        ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                          .MULTIMEDIASECONDS,
                      action: currentLang.code,
                      name: pointSelected.poems[i].identifier,
                      value: Math.round(time),
                    });
                    trackEvent({
                      category:
                        ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                          .MULTIMEDIAPERCENTAGE,
                      action: currentLang.code,
                      name: pointSelected.poems[i].identifier,
                      value: 100,
                    });
                    refs.current[i]?.seekTo(0);
                    setCurrentMedia(-1);
                  }
                  if (element.content_notification.time !== -1) {
                    setTimeout(() => {
                      setCurrentNotification(element);
                      enqueueSnackbar("content");
                    }, element.content_notification.time * 1000);
                  }
                }}
              />
            ) : (
              <AudioPlayer
                id={i + 1}
                name={element.identifier}
                mainAudio={false}
                scrolled={false}
                audioFile={element}
              />
            )}
            <h3 className="text-white text-lg mt-3 mb-8 sm:mb-16">
              {element.title}
            </h3>
          </>
        ))}
      </div>
    </div>
  );
};

export default Poems;
