import create from "zustand";

import {
  ILang,
  PuntoExpositivoData,
  SurveyNotification,
  Survey,
  GeneralTexts,
  AudioVideo,
} from "../utilities/CmsUtils";

interface ICommonStore {
  languageSelected: boolean;
  setLanguageSelected: (value: boolean) => void;

  headerInfoClicked: boolean;
  setHeaderInfoClicked: (value: boolean) => void;

  pointInfoIndex: boolean;
  setPointInfoIndex: (value: boolean) => void;

  dontHideMap: boolean;
  setDontHideMap: (value: boolean) => void;

  lastPagePointInfo: number;
  setLastPagePointInfo: (value: number) => void;

  toggleSendGAInfo: boolean;
  setToggleSendGAInfo: (value: boolean) => void;

  surveyDone: boolean;
  setSurveyDone: (value: boolean) => void;

  intoPoint: boolean;
  setIntoPoint: (value: boolean) => void;

  showPolicy: number;
  setShowPolicy: (value: number) => void;

  isDragging: boolean;
  setIsDragging: (value: boolean) => void;

  surveyCompletedNot: boolean;
  setSurveyCompletedNot: (value: boolean) => void;

  currentMedia: number;
  setCurrentMedia: (value: number) => void;

  pauseVideos: boolean;
  setPauseVideos: (value: boolean) => void;

  currentNotification: AudioVideo | undefined;
  setCurrentNotification: (value: AudioVideo | undefined) => void;

  isAudioPlaying: boolean;
  setIsAudioPlaying: (value: boolean) => void;

  showSurvey: boolean;
  setShowSurvey: (value: boolean) => void;

  showPointsList: boolean;
  setShowPointsList: (value: boolean) => void;

  generalTexts: GeneralTexts | undefined;
  setGeneralTexts: (value: GeneralTexts | undefined) => void;

  currentSurvey: Survey | undefined;
  setCurrentSurvey: (value: Survey | undefined) => void;

  currentSurveyNot: SurveyNotification | undefined;
  setCurrentSurveyNot: (value: SurveyNotification | undefined) => void;

  showInfoPage: boolean;
  setShowInfoPage: (value: boolean) => void;

  currentPoints: PuntoExpositivoData[] | undefined;
  setCurrentPoints: (value: PuntoExpositivoData[] | undefined) => void;

  currentLang: ILang;
  setCurrentLang: (value: ILang) => void;
  showMap: number;
  setShowMap: (value: number) => void;

  showLanguages: boolean;
  setShowLanguages: (value: boolean) => void;

  showLanguagesSelector: boolean;
  setShowLanguagesSelector: (value: boolean) => void;

  hotspotClicked: string;
  setHotspotClicked: (value: string) => void;
  allPointsInfo: PuntoExpositivoData[];
  setAllPointsInfo: (value: PuntoExpositivoData[]) => void;
  pointSelected: PuntoExpositivoData;
  setSelectedPoint: (value: number) => void;
  setNextPoint: () => void;
  setPreviousPoint: () => void;
}

export const CommonStore = create<ICommonStore>((set: any) => ({
  languageSelected: false,
  setLanguageSelected: (value: boolean) =>
    set(() => ({ languageSelected: value })),

  headerInfoClicked: false,
  setHeaderInfoClicked: (value: boolean) =>
    set(() => ({ headerInfoClicked: value })),

  pointInfoIndex: false,
  setPointInfoIndex: (value: boolean) => set(() => ({ pointInfoIndex: value })),

  dontHideMap: false,
  setDontHideMap: (value: boolean) => set(() => ({ dontHideMap: value })),

  lastPagePointInfo: -2,
  setLastPagePointInfo: (value: number) =>
    set(() => ({ lastPagePointInfo: value })),

  toggleSendGAInfo: false,
  setToggleSendGAInfo: (value: boolean) =>
    set(() => ({ toggleSendGAInfo: value })),

  surveyDone: false,
  setSurveyDone: (value: boolean) => set(() => ({ surveyDone: value })),

  intoPoint: false,
  setIntoPoint: (value: boolean) => set(() => ({ intoPoint: value })),

  showPolicy: 0,
  setShowPolicy: (value: number) => set(() => ({ showPolicy: value })),

  isDragging: false,
  setIsDragging: (value: boolean) => set(() => ({ isDragging: value })),

  surveyCompletedNot: false,
  setSurveyCompletedNot: (value: boolean) =>
    set(() => ({ surveyCompletedNot: value })),

  currentMedia: -1,
  setCurrentMedia: (value: number) => set(() => ({ currentMedia: value })),

  pauseVideos: false,
  setPauseVideos: (value: boolean) => set(() => ({ pauseVideos: value })),

  currentNotification: undefined,
  setCurrentNotification: (value: AudioVideo | undefined) =>
    set(() => ({ currentNotification: value })),

  isAudioPlaying: false,
  setIsAudioPlaying: (value: boolean) => set(() => ({ isAudioPlaying: value })),

  showSurvey: false,
  setShowSurvey: (value: boolean) => set(() => ({ showSurvey: value })),

  showPointsList: false,
  setShowPointsList: (value: boolean) => set(() => ({ showPointsList: value })),

  generalTexts: undefined,
  setGeneralTexts: (value: GeneralTexts | undefined) =>
    set(() => ({ generalTexts: value })),

  currentSurvey: {} as Survey,
  setCurrentSurvey: (value: Survey | undefined) =>
    set(() => ({ currentSurvey: value })),

  currentSurveyNot: {} as SurveyNotification,
  setCurrentSurveyNot: (value: SurveyNotification | undefined) =>
    set(() => ({ currentSurveyNot: value })),

  showInfoPage: false,
  setShowInfoPage: (value: boolean) => set(() => ({ showInfoPage: value })),

  currentPoints: [] as PuntoExpositivoData[],
  setCurrentPoints: (value: PuntoExpositivoData[] | undefined) =>
    set(() => ({ currentPoints: value })),

  currentLang: {} as ILang,
  setCurrentLang: (value: ILang) => set(() => ({ currentLang: value })),

  showMap: 0,
  setShowMap: (value: number) => set(() => ({ showMap: value })),

  showLanguages: true,
  setShowLanguages: (value: boolean) => set(() => ({ showLanguages: value })),

  showLanguagesSelector: false,
  setShowLanguagesSelector: (value: boolean) =>
    set(() => ({ showLanguagesSelector: value })),

  hotspotClicked: "",
  setHotspotClicked: (value: string) => set(() => ({ hotspotClicked: value })),

  allPointsInfo: [] as PuntoExpositivoData[],
  setAllPointsInfo: (value: PuntoExpositivoData[]) =>
    set(() => ({ allPointsInfo: value })),

  pointSelected: {} as PuntoExpositivoData,
  setSelectedPoint: (value: number) =>
    set((state: any) => ({
      pointSelected: state.currentPoints[value],
    })),

  setNextPoint: () =>
    set((state: any) => {
      const index = state.currentPoints.findIndex(
        (object: PuntoExpositivoData) => {
          return object.title === state.pointSelected.title;
        }
      );

      if (index === Object.keys(state.currentPoints).length - 1) {
        return { pointSelected: state.currentPoints[0] };
      }

      return { pointSelected: state.currentPoints[index + 1] };
    }),

  setPreviousPoint: () =>
    set((state: any) => {
      const index = state.currentPoints.findIndex(
        (object: PuntoExpositivoData) => {
          return object.title === state.pointSelected.title;
        }
      );

      if (index === 0) {
        return {
          pointSelected:
            state.currentPoints[Object.keys(state.currentPoints).length - 1],
        };
      }

      return { pointSelected: state.currentPoints[index - 1] };
    }),
}));

export default CommonStore;
