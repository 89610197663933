import React, { useState, createContext, useEffect } from "react";
import Cookies from "js-cookie";

interface ICookiesContextState {
  cookies: boolean;
  analyticsCookies: boolean;
  preferenceCookies: boolean;
  addCookies: (ck: boolean, an: boolean, pf: boolean) => void;
}

const contextDefaultValues: ICookiesContextState = {
  cookies: Cookies.get("cookie_consent") === "true" ? true : false,
  analyticsCookies: Cookies.get("an_cookie_consent") === "true" ? true : false,
  preferenceCookies: Cookies.get("pf_cookie_consent") === "true" ? true : false,
  addCookies: () => {},
};

const CookiesContext =
  createContext<ICookiesContextState>(contextDefaultValues);

const removeAllCookies = () => {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    Cookies.remove(cookieName);
  });
};

const removeAnalytics = () => {
  Cookies.remove("an_cookie_consent");
  Cookies.remove("_gat");
  Cookies.remove("_ga");
  Cookies.remove("_gid");
};

const removePreferences = () => {
  Cookies.remove("pf_cookie_consent");
  Cookies.remove("cookie_language");
  //Cookies.remove("cookie_survey");
};

const CookiesProvider: React.FC = ({ children }) => {
  const [cookies, setCookies] = useState<boolean>(contextDefaultValues.cookies);
  const [analyticsCookies, setAnalyticsCookies] = useState<boolean>(
    contextDefaultValues.analyticsCookies
  );
  const [preferenceCookies, setPreferenceCookies] = useState<boolean>(
    contextDefaultValues.preferenceCookies
  );

  useEffect(() => {
    if (!Cookies.get("cookie_consent")) {
      removeAllCookies();
    } else if (!Cookies.get("an_cookie_consent")) {
      removeAnalytics();
    } else if (!Cookies.get("pf_cookie_consent")) {
      removePreferences();
    }
  }, []);

  const addCookies = (ck: boolean, an: boolean, pf: boolean) => {
    setCookies(ck);
    setAnalyticsCookies(an);
    setPreferenceCookies(pf);
  };

  return (
    <CookiesContext.Provider
      value={{ cookies, analyticsCookies, preferenceCookies, addCookies }}
    >
      {children}
    </CookiesContext.Provider>
  );
};

function useCookiesContext() {
  const context = React.useContext(CookiesContext);
  if (context === undefined) {
    throw new Error("useCookiesContext must be used within a CookiesProvider");
  }
  return context;
}

export { CookiesProvider, useCookiesContext };
