import {RefObject, useEffect, useLayoutEffect, useState} from "react";
import {off, on} from "../utilities/utils";
const maxHeigh = 350;
const useScrollPointInfo = (element: RefObject<HTMLDivElement>) => {
  const [scroll, setScroll] = useState<number>();
  const [bottomElement, setBottomElement] = useState<number>();
  const [deltaScroll, setDeltaScroll] = useState(0);
  const handleScroll = () => {
    setScroll(window.pageYOffset);
  };
  useEffect(() => {
    on(window, "scroll", handleScroll, { passive: true });
    return () => {
      off(window, "scroll", handleScroll, {passive: true});
    };
  }, []);

  return {scroll};
};

export default useScrollPointInfo;
