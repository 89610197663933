import React, {useState, forwardRef, useCallback, useEffect} from "react";
import classnames from "classnames";
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar, SnackbarContent} from "notistack";
import Card from "@material-ui/core/Card";
import CommonStore from "../stores/common";
import DoneIcon from "@material-ui/icons/Done";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";
import CloseIcon from "@material-ui/icons/Close";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "344px !important",
    },
  },
  card: {
    backgroundColor: "#3a5cf1",
    width: "100%",
  },
  smallCard: {
    backgroundColor: "#3a5cf1",
    width: "100%",
  },
  errorCard: {
    backgroundColor: "red",
    width: "100%",
  },
}));

const SnackMessage = forwardRef<
  HTMLDivElement,
  {id: string | number; message: string | React.ReactNode}
>((props, ref) => {
  const {trackPageView, trackEvent} = useMatomo();
  const classes = useStyles();
  const currentSurveyNot = CommonStore((state) => state.currentSurveyNot);
  const hotspotClicked = CommonStore((state) => state.hotspotClicked);
  const setShowSurvey = CommonStore((state) => state.setShowSurvey);
  const setShowInfoPage = CommonStore((state: any) => state.setShowInfoPage);
  const setShowMap = CommonStore((state: any) => state.setShowMap);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const pointSelected = CommonStore((state) => state.pointSelected);
  const setShowPointsList = CommonStore((state) => state.setShowPointsList);
  const currentNotification = CommonStore((state) => state.currentNotification);
  const generalTexts = CommonStore((state) => state.generalTexts);
  const setSurveyCompletedNot = CommonStore(
    (state) => state.setSurveyCompletedNot
  );

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      {props.message === "survey" ? (
        <Card className={classes.card}>
          <div className="flex flex-col justify-center text-white px-6 py-4">
            <div className="flex justify-center text-lg font-semibold mb-4">
              {currentSurveyNot?.title}
            </div>
            <div className="mb-8">{currentSurveyNot?.description}</div>
            <div className="flex flex-row justify-evenly mb-1 cursor-pointer">
              <div
                onClick={() => {
                  closeSnackbar(props.id);
                  // GoogleAnalyticsUtilities.RecordUserInteractionEvent({
                  //   category:
                  //     ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                  //       .NOTIFICATION,
                  //   label: "Survey - Not now",
                  // });
                  trackEvent({
                    category:
                      ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                        .NOTIFICATION,
                    action: "clicked",
                    name: "Survey - Not now",
                  });
                }}
              >
                <u>{currentSurveyNot?.not_now_button_message}</u>
              </div>
              <div>
                <button
                  className="encuestaButton"
                  style={{backgroundColor: "#3a5cf1"}}
                  onClick={() => {
                    setShowSurvey(true);
                    setSurveyCompletedNot(false);
                    setShowInfoPage(false);
                    setShowPointsList(false);
                    //setShowMap(false);
                    setShowMap(0);
                    closeSnackbar(props.id);
                    // GoogleAnalyticsUtilities.RecordUserInteractionEvent({
                    //   category:
                    //     ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                    //       .NOTIFICATION,
                    //   label: "Survey - Go to survey",
                    // });
                    trackEvent({
                      category:
                        ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                          .NOTIFICATION,
                      action: "clicked",
                      name: "Survey - Go to survey",
                    });
                  }}
                >
                  {currentSurveyNot?.go_to_survey_button_message}
                </button>
              </div>
            </div>
          </div>
        </Card>
      ) : props.message === "surveySent" ? (
        <Card className={classes.smallCard}>
          <div className="flex flex-row justify-between text-white gap-x-10 py-2 mx-6">
            <div>{currentSurveyNot?.sent_survey_message}</div>
            <DoneIcon />
          </div>
        </Card>
      ) : props.message === "content" && currentNotification !== undefined ? (
        <Card className={classes.card}>
          <div className="flex flex-col justify-center text-white px-6 py-4">
            <div className="flex justify-center text-lg font-semibold mb-4">
              {currentNotification.content_notification.title}
            </div>
            <div className="">
              {currentNotification.content_notification.description}
            </div>
            <div className="mb-1">
              <a
                href={currentNotification.content_notification.url}
                target="_blank"
                onClick={() => {
                  // GoogleAnalyticsUtilities.RecordUserInteractionEvent({
                  //   category:
                  //     ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                  //       .NOTIFICATION,
                  //   label: currentNotification.title,
                  // });
                  trackEvent({
                    category:
                      ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
                        .NOTIFICATION,
                    action: "clicked " + currentNotification.title + " url",
                    name: currentNotification.content_notification.url,
                  });
                }}
              >
                <u>{currentNotification.content_notification.url}</u>
              </a>
            </div>
          </div>
        </Card>
      ) : props.message === "surveyFields" ? (
        <Card className={classes.errorCard}>
          <div className="flex flex-row justify-between text-white gap-x-10 py-2 mx-6">
            <div>{generalTexts?.survey_error}</div>
            <CloseIcon />
          </div>
        </Card>
      ) : (
        <></>
      )}
    </SnackbarContent>
  );
});

export default SnackMessage;
