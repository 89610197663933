import { useState, useEffect } from "react";

import Languages from "../pages/Languages";
import PointsList from "../pages/PointsList";
import PointInfo from "../pages/PointInfo";
import CustomSnackbar from "./CustomSnackbar";
import Map from "../pages/Map";
import ChangeLanguage from "./ChangeLanguage";
import Cookies from "js-cookie";
import { Transition } from "react-transition-group";
import { SnackbarProvider } from "notistack";
import CommonStore from "../stores/common";
import { IWindowDimensions, getWindowDimensions } from "../utilities/utils";
import "../App.css";
import "../styles/PointsListTransitions.css";
import "../styles/ChangeLanguageTransitions.css";
import Survey from "../pages/Survey";
import { useCookiesContext } from "../context/CookiesCtx";
import CookiesModal from "./CookiesModal";
import Footer from "./Footer";
import ProjectConfiguration from "../configuration/project";
// import ReactGA from "react-ga";
import Policy from "./Policy";
import {
  getGeneralTextsFromCMS,
  getLanguages,
  getLanguagesFromCMS,
  ILang,
} from "../utilities/CmsUtils";
import useWindowOrientation from "use-window-orientation";
import landscapeImg from "../assets/images/landscape.svg";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import { isDesktop, isMobile } from "react-device-detect";
import {
  MatomoProvider,
  createInstance,
  useMatomo,
} from "@datapunt/matomo-tracker-react";
import MainElements from "./MainElements";

interface TransitionStyles {
  entering: object;
  entered: object;
  exiting: object;
  exited: object;
}

type TransitionState = keyof TransitionStyles;

const AppNoMatomo = () => {
  return <MainElements />;
};

export default AppNoMatomo;
