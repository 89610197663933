import play from "../assets/images/icons/play.svg";
import pause from "../assets/images/icons/pause.svg";
import previous from "../assets/images/icons/previous.svg";
import next from "../assets/images/icons/next.svg";
import { CommonStore } from "../stores/common";
import { useEffect, useState } from "react";

interface IPlayerControls {
  tooglePlay: () => void;
  mainAudio: boolean;
  isAudioPlaying: boolean;
  changePoint: (action: string) => void;
}

const PlayerControls = ({
  tooglePlay,
  mainAudio,
  isAudioPlaying,
  changePoint,
}: IPlayerControls) => {
  //const [isPlaying, setIsPlaying] = useState<boolean>(false);
  // const audioIsPlaying = CommonStore((state) => state.isAudioPlaying);
  const setNext = CommonStore((state) => state.setNextPoint);
  const setPrevious = CommonStore((state) => state.setPreviousPoint);
  const [buttonLeftAnim, setButtonLeftAnim] = useState(false);
  const [buttonRightAnim, setButtonRightAnim] = useState(false);
  const [buttonCenterAnim, setButtonCenterAnim] = useState(false);

  useEffect(() => {
    if (buttonCenterAnim) {
      setTimeout(() => {
        setButtonCenterAnim(false);
      }, 500);
    }
  }, [buttonCenterAnim]);

  useEffect(() => {
    if (buttonLeftAnim) {
      setTimeout(() => {
        setButtonLeftAnim(false);
      }, 500);
    }
  }, [buttonLeftAnim]);

  useEffect(() => {
    if (buttonRightAnim) {
      setTimeout(() => {
        setButtonRightAnim(false);
      }, 500);
    }
  }, [buttonRightAnim]);

  return (
    <div className="flex justify-center items-center">
      {mainAudio && (
        <div
          className={`cursor-pointer flex-1 flex justify-end items-center ${
            buttonLeftAnim ? "buttonClicked" : ""
          }`}
        >
          {" "}
          <img
            width="25"
            onClick={() => {
              setButtonLeftAnim(true);
              //setIsPlaying(false);
              changePoint("previous");
            }}
            src={previous}
          />
        </div>
      )}

      <div
        className={`cursor-pointer flex-1 flex justify-center items-center px-12 ${
          buttonCenterAnim ? "buttonClicked" : ""
        }`}
      >
        {" "}
        <img
          width="75"
          src={!isAudioPlaying ? play : pause}
          onClick={() => {
            setButtonCenterAnim(true);
            tooglePlay();
            //setIsPlaying(!isPlaying);
          }}
        />
      </div>
      {mainAudio && (
        <div
          className={`cursor-pointer flex-1 flex justify-start items-center ${
            buttonRightAnim ? "buttonClicked" : ""
          }`}
        >
          <img
            width="25"
            onClick={() => {
              setButtonRightAnim(true);
              //setIsPlaying(false);
              changePoint("next");
            }}
            src={next}
          />
        </div>
      )}
    </div>
  );
};

export default PlayerControls;
