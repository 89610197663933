import { useState, useEffect, useRef } from "react";
import Draggable from "react-draggable";
import PlayerProgresBar from "./PlayerProgressBar";
import PlayerControls from "./PlayerControls";
import audioSrc from "../assets/audio/bg-audio.mp3";
import { CommonStore } from "../stores/common";
import { useSnackbar } from "notistack";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";
import { AudioVideo, PuntoExpositivoData } from "../utilities/CmsUtils";
import Lottie from "react-lottie-player";
import loadingAnimation from "../assets/animations/loading.json";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import logo from "../assets/images/logoSpinner.svg";

interface IAudioPlayerProps {
  id: number;
  name: string;
  scrolled: boolean;
  mainAudio: boolean;
  audioFile: AudioVideo;
  changePoint?: (action: string) => void;
}

const AudioPlayer = ({
  id,
  name,
  scrolled,
  mainAudio,
  audioFile,
  changePoint,
}: IAudioPlayerProps) => {
  const { trackPageView, trackEvent } = useMatomo();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const pointSelected = CommonStore((state) => state.pointSelected);
  // const isAudioPlaying = CommonStore((state) => state.isAudioPlaying);
  // const setIsAudioPlaying = CommonStore((state) => state.setIsAudioPlaying);
  const [ended, setEnded] = useState<boolean>(false);
  const [trackProgress, setTrackProgress] = useState<number>(0);
  const [percentajeProgress, setPercentajeProgress] = useState<number>(0);
  const currentNotification = CommonStore((state) => state.currentNotification);
  const showInfoPage = CommonStore((state) => state.showInfoPage);
  const setPauseVideos = CommonStore((state) => state.setPauseVideos);
  const pauseVideos = CommonStore((state) => state.setPauseVideos);
  const setCurrentMedia = CommonStore((state) => state.setCurrentMedia);
  const currentMedia = CommonStore((state) => state.currentMedia);
  const setToggleSendGAInfo = CommonStore((state) => state.setToggleSendGAInfo);
  const toggleSendGAInfo = CommonStore((state) => state.toggleSendGAInfo);
  const showMap = CommonStore((state) => state.showMap);
  const setCurrentNotification = CommonStore(
    (state) => state.setCurrentNotification
  );
  const currentLang = CommonStore((state) => state.currentLang);
  const isDragging = CommonStore((state) => state.isDragging);
  const headerInfoClicked = CommonStore((state) => state.headerInfoClicked);
  const setHeaderInfoClicked = CommonStore(
    (state) => state.setHeaderInfoClicked
  );

  const [isAudioPlaying, setIsAudioPlaying] = useState(false);

  // Refs
  const audioRef = useRef(new Audio());
  const intervalRef = useRef<NodeJS.Timer>();
  // Destructure for conciseness
  //const { duration } = audioRef.current;
  const [mediaDuration, setMediaDuration] = useState<number>(0);
  const [isMediaLoaded, setIsMediaLoaded] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current as NodeJS.Timer);
    };
  }, []);

  useEffect(() => {
    audioRef.current.src = audioFile.file;
  }, [pointSelected]);

  audioRef.current.onloadedmetadata = function () {
    setIsMediaLoaded(true);
    setMediaDuration(audioRef.current.duration);
  };

  useEffect(() => {
    if (currentMedia === id && isAudioPlaying) {
      if (isDragging) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  }, [isDragging]);

  useEffect(() => {
    if (isAudioPlaying) {
      audioRef.current.play();
      startTimer();
      setCurrentMedia(id);
      setPauseVideos(!pauseVideos);
    } else {
      audioRef.current.pause();
      clearInterval(intervalRef.current as NodeJS.Timer);
    }
  }, [isAudioPlaying]);

  useEffect(() => {
    if (currentMedia !== id) {
      setIsAudioPlaying(false);
    }
  }, [currentMedia]);

  useEffect(() => {
    setPercentajeProgress(trackProgress);
  }, [trackProgress]);

  useEffect(() => {
    if (showMap) {
      setTrackProgress(0);
      setIsAudioPlaying(false);
      audioRef.current.currentTime = 0;
    }
  }, [showMap]);

  const startTimer = () => {
    clearInterval(intervalRef.current as NodeJS.Timer);
    intervalRef.current = setInterval(() => {
      if (audioRef.current.currentTime >= mediaDuration) {
        trackEvent({
          category:
            ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIASECONDS,
          action: currentLang.code,
          name: name,
          value: Math.round(audioRef.current.duration),
        });
        trackEvent({
          category:
            ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIAPERCENTAGE,
          action: currentLang.code,
          name: name,
          value: 100,
        });
        if (audioFile.content_notification.time !== -1) {
          setTimeout(() => {
            setCurrentNotification(audioFile);
            enqueueSnackbar("content");
          }, audioFile.content_notification.time * 1000);
        }
        setTrackProgress(0);
        setIsAudioPlaying(false);
        audioRef.current.currentTime = 0;
        return;
      }
      setTrackProgress(audioRef.current.currentTime);
    }, 1000);
  };

  const seekAudio = (value: number) => {
    if (value === 0) {
      setIsAudioPlaying(false);
      audioRef.current.currentTime = 0;
      return;
    }
    audioRef.current.currentTime = (value * audioRef.current.duration) / 100;
    setTrackProgress(audioRef.current.currentTime);
  };

  useEffect(() => {
    if (
      audioRef.current.currentTime !== 0 &&
      audioRef.current.currentTime !== null &&
      audioRef.current.currentTime !== undefined
    ) {
      // console.log("AUDIO POEM");
      // console.log(name);
      // console.log(audioRef.current.currentTime);

      trackEvent({
        category:
          ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIASECONDS,
        action: currentLang.code,
        name: name,
        value: Math.round(audioRef.current.currentTime),
      });
      trackEvent({
        category:
          ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.MULTIMEDIAPERCENTAGE,
        action: currentLang.code,
        name: name,
        value: Math.round(
          (audioRef.current.currentTime / audioRef.current.duration) * 100
        ),
      });
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [toggleSendGAInfo]);

  // CommonStore.subscribe((state) => {
  //   if (
  //     state.pointSelected.audio.file &&
  //     state.pointSelected.audio.file != audioRef.current.src
  //   ) {
  //     audioRef.current.src = audioFile.file;
  //   }
  // });

  return (
    <div
      className="relative flex flex-col"
      style={{ height: 140, zIndex: 500 }}
    >
      <div
        style={{ opacity: isMediaLoaded ? "" : 0.2 }}
        className={`${!isMediaLoaded ? "" : "fade-in-02"}`}
      >
        <PlayerProgresBar
          seekAudio={seekAudio}
          totalDuration={mediaDuration}
          progress={percentajeProgress}
        />
        <PlayerControls
          tooglePlay={() => {
            setIsAudioPlaying(!isAudioPlaying);
          }}
          isAudioPlaying={isAudioPlaying}
          mainAudio={mainAudio}
          changePoint={(action: string) => {
            // if (
            //   audioRef.current.currentTime !== 0 &&
            //   audioRef.current.currentTime !== null &&
            //   audioRef.current.currentTime !== undefined
            // ) {
            //   trackEvent({
            //     category:
            //       ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
            //         .MULTIMEDIASECONDS,
            //     action: currentLang.code,
            //     name: name,
            //     value: Math.round(audioRef.current.currentTime),
            //   });
            //   trackEvent({
            //     category:
            //       ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY
            //         .MULTIMEDIAPERCENTAGE,
            //     action: currentLang.code,
            //     name: name,
            //     value: Math.round(
            //       (audioRef.current.currentTime / audioRef.current.duration) *
            //         100
            //     ),
            //   });
            // }

            setToggleSendGAInfo(!toggleSendGAInfo);
            // audioRef.current.pause();
            // audioRef.current.currentTime = 0;
            setIsAudioPlaying(false);
            if (changePoint) {
              changePoint(action);
            }
            setPercentajeProgress(0);
          }}
        />
      </div>
      {!isMediaLoaded && (
        <div className="text-white h-full absolute top-0 w-full flex flex-col items-center mt-4">
          {/* <Lottie animationData={loadingAnimation} play style={{ width: 75 }} /> */}
          <img className="spining" src={logo} width={30} />
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;

//https://tipsfordev.com/typescript-error-setinterval-type-timer-is-not-assignable-to-type-number
//https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
//https://dev.to/ramonak/react-how-to-create-a-custom-progress-bar-component-in-5-minutes-2lcl
