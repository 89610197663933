import { useEffect } from "react";
import CommonStore from "../stores/common";
import logo from "../assets/images/icons/TG_landsc.svg";
import CloseIcon from "@material-ui/icons/Close";
import { motion } from "framer-motion";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Policy = () => {
  const { trackPageView, trackEvent } = useMatomo();

  const generalTexts = CommonStore((state) => state.generalTexts);
  const showPolicy = CommonStore((state) => state.showPolicy);
  const setShowPolicy = CommonStore((state) => state.setShowPolicy);
  const setShowPointsList = CommonStore((state) => state.setShowPointsList);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (showPolicy === 1) {
      trackPageView({
        documentTitle: "Página Política de Privacidad", // optional
      });
    } else {
      trackPageView({
        documentTitle: "Página Política de Cookies", // optional
      });
    }
  }, [showPolicy]);

  return generalTexts !== undefined ? (
    <div
      className="w-full absolute top-0 text-white flex flex-col items-center lg:items-start"
      style={{ minHeight: "100vh", backgroundColor: "#0900b9", zIndex: 200 }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0,
        }}
        className="sticky top-0 w-full h-36 flex flex-row content-center"
        style={{ backgroundColor: "#0900b9", zIndex: 10 }}
      >
        <div className="flex flex-col p-5 sm:p-7">
          <img className="" src={logo} width={180} />
          <div className="uppercase mt-4 text-lg font-bold">
            {showPolicy === 1
              ? generalTexts.privacy_policy
              : generalTexts.cookies_policy}
          </div>
        </div>

        <div
          onClick={() => {
            trackPageView({
              documentTitle: "Página Lista Puntos de Exposición", // optional
            });
            setShowPolicy(0);
            setShowPointsList(true);
          }}
          className="absolute top-0 right-0 mr-4 mt-4 sm:mr-6 sm:mt-6 cursor-pointer"
        >
          <CloseIcon />
        </div>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.5,
          delay: 0.2,
        }}
        className="mt-4 sm:mt-8 w-10/12 sm:w-3/4 lg:w-1/2 lg:ml-24 mb-24"
      >
        <div
          dangerouslySetInnerHTML={{
            __html:
              showPolicy === 1
                ? generalTexts.privacy_policy_text
                : generalTexts.cookies_policy_text,
          }}
        ></div>
      </motion.div>
    </div>
  ) : (
    <></>
  );
};

export default Policy;
