import { useState, useEffect } from "react";
import proximo from "../assets/images/icons/proximo.svg";
import logo from "../assets/images/icons/TG_logo.svg";
import charla from "../assets/images/icons/charla.svg";
import ubicacion from "../assets/images/icons/ubicacion.svg";
import Icon from "./SVGs/Charla";
import CommonStore from "../stores/common";
import bg from "../assets/images/Frame.jpg";
import bg2 from "../assets/images/fondo.jpg";
import { isDesktop } from "react-device-detect";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const bgs = [bg, bg2];

interface IHeaderInfoPage {
  scrolled: boolean;
  styles: object;
}

const HeaderInfoPage = ({ scrolled, styles }: IHeaderInfoPage) => {
  const setShowMap = CommonStore((state: any) => state.setShowMap);
  const setShowInfoPage = CommonStore((state: any) => state.setShowInfoPage);
  const setShowPointsList = CommonStore(
    (state: any) => state.setShowPointsList
  );
  const pointSelected = CommonStore((state) => state.pointSelected);
  const setHotspotClicked = CommonStore(
    (state: any) => state.setHotspotClicked
  );
  const currentPoints = CommonStore((state) => state.currentPoints);
  const setIntoPoint = CommonStore((state) => state.setIntoPoint);
  const setLastPagePointInfo = CommonStore(
    (state) => state.setLastPagePointInfo
  );
  const hotspotClicked = CommonStore((state) => state.hotspotClicked);

  const setToggleSendGAInfo = CommonStore((state) => state.setToggleSendGAInfo);
  const toggleSendGAInfo = CommonStore((state) => state.toggleSendGAInfo);
  const setHeaderInfoClicked = CommonStore(
    (state) => state.setHeaderInfoClicked
  );
  const { trackPageView, trackEvent } = useMatomo();

  function getSelectedPointIndex() {
    return currentPoints !== undefined
      ? currentPoints.findIndex(
          (element) => element.title === pointSelected.title
        )
      : 0;
  }

  function showMap() {
    //setShowInfoPage(false);
    setTimeout(() => {
      setHotspotClicked(pointSelected.title);
      setLastPagePointInfo(getSelectedPointIndex());
    }, 500);

    if (currentPoints !== undefined) {
      var point = currentPoints.find(
        (element) => element.title === pointSelected.title
      );
      if (point?.map === "First Floor") {
        setShowMap(1);
      } else {
        setShowMap(2);
      }
    }
  }

  return (
    <div
      className="relative bg-no-repeat bg-cover bg-center"
      style={{ ...styles, backgroundImage: `url(${pointSelected.image})` }}
    >
      <div
        className={`absolute w-full h-full ${
          isDesktop ? "imageFade" : scrolled ? "imageFadeScrolled" : "imageFade"
        }`}
        style={{ backgroundColor: "#0900b9" }}
      ></div>
      <div
        className="absolute right-0 p-4"
        style={{
          top: scrolled ? "50%" : "0",
          transform: scrolled ? "translateY(-50%)" : "",
          zIndex: 50,
        }}
      >
        <img
          onClick={() => {
            setHeaderInfoClicked(true);
            setToggleSendGAInfo(!toggleSendGAInfo);
            setTimeout(() => {
              window.scrollTo(0, 0);
              showMap();
            }, 100);
          }}
          src={ubicacion}
          alt=""
        />
      </div>

      <div
        className="absolute w-full left-0 p-4"
        style={{
          top: scrolled ? "50%" : "",
          bottom: scrolled ? "" : "0",
          transform: scrolled ? "translateY(-50%)" : "",
          marginBottom: 10,
        }}
      >
        <img
          className={`${
            scrolled ? "float-left mr-8 mt-2 lg:mt-4" : "mb-4"
          } cursor-pointer`}
          src={proximo}
          alt=""
          style={{ transform: "rotate(-180deg)" }}
          onClick={() => {
            trackPageView({
              documentTitle: "Página Lista Puntos de Exposición", // optional
            });
            setHeaderInfoClicked(true);
            setToggleSendGAInfo(!toggleSendGAInfo);
            setIntoPoint(false);
            setShowInfoPage(false);
            window.scrollTo(0, 0);
          }}
        />{" "}
        <h1
          className={`${
            scrolled ? "text-xl mt-2 lg:text-3xl" : "text-2xl"
          } text-white pl-2 w-full mb-2`}
        >
          {pointSelected.title}
        </h1>
        {/* <h1 className={`text-2xl text-white pl-2`}>{pointSelected.title}</h1> */}
      </div>

      {/* <div className="flex justify-end align-center">
        <img onClick={() => showMap()} src={ubicacion} alt="" />
      </div>

      <div className="flex flex-col mt-auto ">
        <div>
          <img
            src={proximo}
            alt=""
            style={{transform: "rotate(-180deg)"}}
            onClick={() => setShowInfoPage(false)}
          />
        </div>
        <div className="py-4">
          <h1 className="text-3xl text-white">{pointSelected.title}</h1>
        </div>
      </div> */}
    </div>
  );
};

export default HeaderInfoPage;
