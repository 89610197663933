import {useState, useEffect, useRef} from "react";

import Header from "../components/Header";
import ActionRectangle from "../components/ActionRectangle";
import {getWindowDimensions, IWindowDimensions} from "../utilities/utils";
import {CommonStore} from "../stores/common";
import {isTablet} from "./../utilities/utils";
import {
  getPointsFromCMS,
  getPoints,
  getSurveyNot,
  getSurveyNotFromCMS,
  getSurvey,
  getSurveyFromCMS,
  getGeneralTextsFromCMS,
  getGeneralTexts,
} from "../utilities/CmsUtils";
import useScrollingUp from "../hooks/useScrollingUp";
import HeaderSticky from "../components/HeaderSticky";
import Lottie from "react-lottie-player";
import loadingAnimation from "../assets/animations/loading.json";
import {useSnackbar} from "notistack";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";
import logo from "../assets/images/logoSpinner.svg";
import {motion} from "framer-motion";
import background from "../assets/images/hero.jpg";
import background2 from "../assets/images/fondo_vert2.jpg";
import CookiesModal from "../components/CookiesModal";
import {useCookiesContext} from "../context/CookiesCtx";
import {Transition} from "react-transition-group";
import {isDesktop} from "react-device-detect";
import {useMatomo} from "@datapunt/matomo-tracker-react";

const PointsList = () => {
  const {trackPageView, trackEvent} = useMatomo();
  const pointsContainerRef = useRef<HTMLDivElement>(null);
  const scrolled = useScrollingUp();
  const setSelectedPoint = CommonStore((state) => state.setSelectedPoint);

  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions()
  );

  const currentLang = CommonStore((state) => state.currentLang);
  const setCurrentPoints = CommonStore((state) => state.setCurrentPoints);
  const currentPoints = CommonStore((state) => state.currentPoints);
  const setShowInfoPage = CommonStore((state: any) => state.setShowInfoPage);
  const setCurrentSurveyNot = CommonStore((state) => state.setCurrentSurveyNot);
  const setCurrentSurvey = CommonStore((state) => state.setCurrentSurvey);
  const setGeneralTexts = CommonStore((state) => state.setGeneralTexts);
  const showInfoPage = CommonStore((state) => state.showInfoPage);
  const surveyCompletedNot = CommonStore((state) => state.surveyCompletedNot);
  const showMap = CommonStore((state) => state.showMap);
  const showPointsList = CommonStore((state) => state.showPointsList);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [containerHeight, setContainerHeight] = useState<number>(
    getWindowDimensions().height + 50
  );
  const {cookies, preferenceCookies} = useCookiesContext();

  useEffect(() => {
    window.scrollTo(0, 0);

    function handleScroll() {
      setContainerHeight(
        pointsContainerRef.current!.getBoundingClientRect().height
      );
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   if (!showInfoPage && showMap === 0) {
  //     trackPageView({
  //       documentTitle: "Página Lista Puntos de Exposición", // optional
  //     });
  //   }
  // }, []);

  useEffect(() => {
    setCurrentPoints(undefined);
    getGeneralTextsFromCMS(currentLang.code).finally(() => {
      setGeneralTexts(getGeneralTexts());
      getPointsFromCMS(currentLang.code).finally(() => {
        setCurrentPoints(getPoints(currentLang.code));
        getSurveyNotFromCMS(currentLang.code).finally(() => {
          setCurrentSurveyNot(getSurveyNot());
          getSurveyFromCMS(currentLang.code).finally(() => {
            setCurrentSurvey(getSurvey());
          });
        });
      });
    });
  }, [currentLang]);

  useEffect(() => {
    if (!surveyCompletedNot) {
      closeSnackbar();
    }
  }, [showInfoPage, showMap]);

  const [hideBg, setHideBg] = useState(false);

  useEffect(() => {
    if (scrolled) {
      setTimeout(() => {
        setHideBg(true);
      }, 900);
    } else {
      setTimeout(() => {
        setHideBg(false);
      }, 900);
    }
  }, [scrolled]);

  return (
    <div className="app" style={{backgroundColor: "#0900ba"}}>
      {/* {!scrolled && (
        <img
          src={background}
          style={{ height: "70vh" }}
          className="absolute w-full top-0"
        />
      )} */}

      <img
        src={background2}
        style={{height: "70vh"}}
        className={`w-full top-0 ${scrolled ? "fade-out" : "fade-in"} ${
          hideBg ? "opacity-0" : "opacity-1"
        }`}
      />

      <Transition
        in={!scrolled}
        timeout={500}
        appear
        unmountOnExit={true}
        onEntered={() => {}}
        // onExit={() => {
        //   console.log("EXIT MENU");
        // }}
      >
        {(status) => {
          return (
            <div className={`header header-${status}`}>
              <Header currentPage={"pointsList"} />
            </div>
          );
        }}
      </Transition>
      <Transition
        in={scrolled}
        timeout={500}
        appear
        unmountOnExit={true}
        onEntered={() => {}}
        // onExit={() => {
        //   console.log("EXIT MENU STIKY");
        // }}
      >
        {(status) => {
          return (
            <div
              className={`sticky-header sticky-header-${status} ${
                scrolled ? "boxHeader" : ""
              }`}
              style={{zIndex: 100}}
            >
              <HeaderSticky />
            </div>
          );
        }}
      </Transition>
      {/* {scrolled ? (
        <HeaderSticky />
      ) : (
        <>
          {" "}
          <img
            src={background}
            style={{ height: "70vh" }}
            className="absolute w-full top-0"
          />
          <Header currentPage={"pointsList"} />
        </>
      )} */}

      {currentPoints !== undefined ? (
        <div
          ref={pointsContainerRef}
          className={`px-8 pb-24 ${
            scrolled ? "absolute top-0" : "absolute top-0"
          } `}
          style={{
            marginTop: isDesktop ? 220 : isTablet() ? 200 : 160,
            width: windowDimensions.width,
            //minHeight: windowDimensions.height,
          }}
        >
          {currentPoints?.map((point, i) => (
            <ActionRectangle
              key={i}
              title={point.title}
              subtitle={point.description}
              numberName={point.number_name}
              componentStyles={`w-full sm:w-1/2  py-4 ${
                point.number_name > 0 ? "sm:ml-16 ml-3" : "sm:ml-12"
              } `}
              onClickAction={() => {
                setSelectedPoint(i);
                setShowInfoPage(true);
                // trackEvent({
                //   category: ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.AREA,
                //   action: "event",
                //   name: point.title,
                // });
                // console.log("POINTLIST");
              }}
              titleStyles={"text-lg"}
            ></ActionRectangle>
          ))}
          {!cookies && <CookiesModal />}
        </div>
      ) : (
        <motion.div
          initial={{opacity: 0}}
          animate={{opacity: 1}}
          transition={{
            duration: 0.5,
            delay: 0.3,
          }}
          className="absolute top-0 h-full w-full flex justify-center"
        >
          <img className="spining" src={logo} width={30} />
          {/* <Lottie
            animationData={loadingAnimation}
            play
            style={{
              width: isTablet() ? 120 : 90,
            }}
          /> */}
        </motion.div>
      )}
    </div>
  );
};

export default PointsList;
