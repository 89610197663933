import React, {useState, useEffect} from "react";
import {IWindowDimensions, getWindowDimensions} from "../utilities/utils";
import CommonStore from "../stores/common";
import "../styles/ChangeLanguageTransitions.css";
import ActionRectange2 from "./ActionRectangle2";
import charla from "../assets/images/icons/charla.svg";
import {getLanguages, getLanguagesFromCMS, ILang} from "../utilities/CmsUtils";
import GoogleAnalyticsUtilities from "../utilities/googleAnalyticsUtilities";
import ProjectConfiguration from "../configuration/project";
import {motion} from "framer-motion";
import Cookies from "js-cookie";
import {useCookiesContext} from "../context/CookiesCtx";
import CloseIcon from "@material-ui/icons/Close";
import {useMatomo} from "@datapunt/matomo-tracker-react";

interface IChangeLanguageProps {
  closeWindow: () => void;
}

const ChangeLanguage = ({closeWindow}: IChangeLanguageProps) => {
  const {trackPageView, trackEvent} = useMatomo();
  const setLanguage = CommonStore((state: any) => state.setCurrentLang);
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>(
    getWindowDimensions()
  );
  const setShowLanguagesSelector = CommonStore(
    (state: any) => state.setShowLanguagesSelector
  );
  const {cookies, preferenceCookies} = useCookiesContext();

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [languages, setLanguages] = useState<ILang[] | undefined>();
  useEffect(() => {
    window.scrollTo(0, 0);
    trackPageView({
      documentTitle: "Página Cambiar Idioma", // optional
    });
    getLanguagesFromCMS().finally(() => {
      setLanguages(getLanguages());
    });
  }, []);

  return (
    <div
      className="bg-rectangle-blue bg-no-repeat bg-cover bg-main-position flex items-start pl-8 w-full left-0 pb-24"
      style={{
        width: windowDimensions.width,
        minHeight: windowDimensions.height,
      }}
    >
      {" "}
      <div
        onClick={() => {
          trackPageView({
            documentTitle: "Página Lista Puntos de Exposición", // optional
          });
          setShowLanguagesSelector(false);
        }}
        className="absolute top-0 right-0 lg:mr-8 mr-4 mt-4 sm:mr-6 sm:mt-6 cursor-pointer text-white"
      >
        <CloseIcon />
      </div>
      {languages !== undefined && (
        <div className="w-full">
          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{
              duration: 0.5,
              delay: 0.1,
            }}
          >
            <img src={charla} alt="" className="py-8 sm:py-16 sm:pl-4" />
          </motion.div>

          <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}
          >
            {languages.map((l, i) => (
              <ActionRectange2
                key={i}
                title={l.name}
                componentStyles={
                  "w-2/3 mx-8 sm:mx-16 sm:w-1/2 py-3 lg:w-1/3 lg:mx-20"
                }
                titleStyles={"text-2xl"}
                onClickAction={() => {
                  if (cookies && preferenceCookies)
                    Cookies.set("cookie_language", l.code);
                  setLanguage(l);
                  closeWindow();
                  trackPageView({
                    documentTitle: "Página Lista Puntos de Exposición", // optional
                  });
                  // GoogleAnalyticsUtilities.RecordUserInteractionEvent({
                  //   category:
                  //     ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.LANGUAGE,
                  //   label: l.code,
                  // });
                  // trackEvent({
                  //   category:
                  //     ProjectConfiguration.GOOGLE_ANALYTICS_CATEGORY.LANGUAGE,
                  //   action: "selected",
                  //   name: l.code,
                  // });
                }}
              ></ActionRectange2>
            ))}
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default ChangeLanguage;
