import * as React from "react";
import { SVGProps } from "react";
import { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";
import CommonStore from "../stores/common";
import { isChrome, isIOS } from "react-device-detect";

const ChangeMap = () => {
  const showMap = CommonStore((state: any) => state.showMap);
  const setShowMap = CommonStore((state: any) => state.setShowMap);
  const generalTexts = CommonStore((state) => state.generalTexts);
  const setHotspotClicked = CommonStore(
    (state: any) => state.setHotspotClicked
  );
  const hotspotClicked = CommonStore((state: any) => state.hotspotClicked);

  return (
    <div className="flex flex-row w-full" style={{ zIndex: 115 }}>
      <div
        className="flex flex-col w-1/2"
        style={{ borderBottom: "0.5px solid white" }}
      >
        <button
          className="flex justify-center text-white px-4 py-2"
          style={{ backgroundColor: "#0900b9" }}
          onClick={() => {
            setShowMap(1);
            setHotspotClicked("");
          }}
        >
          <div>{generalTexts?.first_floor}</div>
        </button>
        <div>
          <hr
            className={showMap === 1 ? "linePressedButton" : "lineButton"}
          ></hr>
        </div>
      </div>

      <div
        className="flex flex-col w-1/2"
        style={{ borderBottom: "0.5px solid white" }}
      >
        <button
          className="flex justify-center text-white py-2"
          style={{ backgroundColor: "#0900b9" }}
          onClick={() => {
            setShowMap(2);
            setHotspotClicked("");
          }}
        >
          <div>{generalTexts?.dome}</div>
        </button>
        <div>
          <hr
            className={showMap === 2 ? "linePressedButton" : "lineButton"}
          ></hr>
        </div>
      </div>
    </div>
  );
};

export default ChangeMap;
