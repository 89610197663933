import { useEffect } from "react";
import CommonStore from "../stores/common";
import { motion } from "framer-motion";

const Footer = () => {
  const generalTexts = CommonStore((state) => state.generalTexts);
  const setShowPolicy = CommonStore((state) => state.setShowPolicy);
  const setShowLanguages = CommonStore((state) => state.setShowLanguages);
  const setShowPointsList = CommonStore((state) => state.setShowPointsList);
  const setShowInfoPage = CommonStore((state) => state.setShowInfoPage);
  const showMap = CommonStore((state) => state.showMap);
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.1,
      }}
      className="fixed bottom-0 left-0 w-full text-xs text-white flex flex-col bg-blue-300"
      style={{ zIndex: 300 }}
    >
      <div
        className={`flex py-3 justify-center items-center ${
          showMap ? "footerBoxMap" : "footerBox"
        }`}
        style={{ backgroundColor: "#0900b9" }}
      >
        <div
          className="mx-8 cursor-pointer"
          onClick={() => {
            setShowLanguages(false);
            setShowPointsList(false);
            setShowInfoPage(false);
            setShowPolicy(1);
          }}
        >
          {generalTexts !== undefined
            ? generalTexts.privacy_policy
            : "Privacy policy"}
        </div>
        <div
          className="mx-8 cursor-pointer"
          onClick={() => {
            setShowLanguages(false);
            setShowPointsList(false);
            setShowInfoPage(false);
            setShowPolicy(2);
          }}
        >
          {generalTexts !== undefined
            ? generalTexts.cookies_policy
            : "Cookies policy"}
        </div>
      </div>
    </motion.div>
  );
};

export default Footer;
