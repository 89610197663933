import CommonStore from "../stores/common";

import ubicacion from "../assets/images/icons/ubicacion.svg";
import logo from "../assets/images/icons/TG_landsc.svg";
import survey from "../assets/images/icons/happy.svg";
import {motion} from "framer-motion";
import Cookies from "js-cookie";

const HeaderSticky = () => {
  const currentLang = CommonStore((state: any) => state.currentLang);
  const setShowSurvey = CommonStore((state) => state.setShowSurvey);
  const setShowInfoPage = CommonStore((state: any) => state.setShowInfoPage);
  const setShowMap = CommonStore((state) => state.setShowMap);
  const setShowPointsList = CommonStore((state) => state.setShowPointsList);
  const surveyDone = CommonStore((state) => state.surveyDone);
  const setShowLanguagesSelector = CommonStore(
    (state: any) => state.setShowLanguagesSelector
  );
  const setSurveyCompletedNot = CommonStore(
    (state) => state.setSurveyCompletedNot
  );
  const setLastPagePointInfo = CommonStore(
    (state) => state.setLastPagePointInfo
  );

  function showMap() {
    setLastPagePointInfo(-1);
    setShowMap(1);
  }

  return (
    <div
      className={`shrinkHeader bg-hero-pattern bg-no-repeat bg-cover bg-center py-8 px-6 sm:py-8 sm:px-12 w-full sticky top-0 `}
      style={{height: 100}}
    >
      <div className="float-left">
        {" "}
        <img src={logo} alt="" />
      </div>
      <div className="float-right">
        <img
          onClick={() => showMap()}
          className="float-left mr-3 sm:mr-5"
          src={ubicacion}
          alt=""
        />
        {(Cookies.get("cookie_survey") == "false" ||
          Cookies.get("cookie_survey") == undefined) &&
          !surveyDone && (
            <img
              onClick={() => {
                setShowSurvey(true);
                setSurveyCompletedNot(false);
                setShowInfoPage(false);
                setShowPointsList(false);
                //setShowMap(false);
                setShowMap(0);
              }}
              className="float-left cursor-pointer mr-4 sm:mr-5"
              style={{width: "1.85rem"}}
              src={survey}
              alt=""
            />
          )}
        <button
          onClick={() => {
            setShowMap(0);
            setShowLanguagesSelector(true);
          }}
          className="text-white border-white px-2 uppercase"
          style={{
            border: "1px solid white",
            padding: "2px 8px 2px 8px",
          }}
        >
          {currentLang.short_name}
        </button>
      </div>
    </div>
  );
};

export default HeaderSticky;
