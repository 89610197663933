import ProjectConfiguration from "../configuration/project";
import {AuthenticationResponse, LoginCredentials} from "./types";
import {Login /*, Logout */} from "./authentication";

/**
 * Assets
 *
 *  - The /assets endpoint can be used to stream or retrieve the actual file contents from assets managed within Directus.
 */

export interface GetAssetNamedParameters {
  loginCredentials?: LoginCredentials | undefined;
  fileID: string;
}

/**
 * Accessing an Original File
 *
 *  - The location of your actual file originals is based on the project's configuration, but you can consistently access them via the API using the following URL.
 *  - example.com/assets/<file-id>
 *
 * @param loginCredentials?
 * @param fileID
 */
export async function GetAsset({
  loginCredentials,
  fileID,
}: GetAssetNamedParameters): Promise<any> {
  let url: Blob = new Blob();

  if (loginCredentials) {
    await Login(loginCredentials)
      .then(async (response: AuthenticationResponse) => {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + response.access_token,
          },
        };

        await fetch(
          `${ProjectConfiguration.DIRECTUS_BASE_URL}/assets/${fileID}`,
          requestOptions
        )
          .then(async (response) => {
            //console.log("ASSET");
            if (!response.ok) {
              // Get Error Message from Body or Default to Response Status
              const error = response.status;
              // Return throwing Error obtained
              return Promise.reject(error);
            }

            await response.blob().then((blob) => {
              // url = URL.createObjectURL(blob);
              url = blob;
            });
          })
          .catch((error) => {
            console.error(error); //
          });

        // if (process.env.NODE_ENV.includes("production")) {
        //   await Logout(response.refresh_token);
        // }
      })
      .catch((error) => {
        console.error(error);
      });
  } else {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    await fetch(
      `${ProjectConfiguration.DIRECTUS_BASE_URL}/assets/${fileID}`,
      requestOptions
    )
      .then(async (response) => {
        if (!response.ok) {
          // Get Error Message from Body or Default to Response Status
          const error = response.status;
          // Return throwing Error obtained
          return Promise.reject(error);
        }

        await response.blob().then((blob) => {
          url = blob;
          // url = URL.createObjectURL(blob);
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return url;
}
